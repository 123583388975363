import * as React from "react"

import { ContactFormContextProvider } from "../../core/contexts/contactForm"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo/seo"
import Divider from "../../components/divider/divider"
import ContactForm from "../../components/contactForm/contactForm"
import Card from "../../components/card/card"
import TextButton from "../../components/buttons/textButton"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

const description = "An accelerated approach to optimizing digital revenue."

const OptimizingYourSalesAndMarketingPage = () => {
  return (
    <Layout>
      <Seo
        title="Solutions | Optimizing Your Sales and Marketing"
        description={description}
      />
      <section className="container mt-5">
        <div className="mw-800">
          <h4>Optimizing Your Sales and Marketing</h4>
          <p className="subtitle1">{description}</p>
        </div>
        <Divider />
      </section>
      <section className="mt-5">
        <Card canHover={false} isPrimary={true} isPadded={false}>
          <div className="container pt-2 pb-2">
            <h6 className="mw-800">
              As of January 2021, there were 38.13 million active internet users
              in South Africa and nearly 99 percent of those using social media
              accesed their accounts through mobile phones.
            </h6>
            <TextButton
              label="Source: Statista | South Africa: digital population as of January 2021"
              icon={faArrowRight}
              isExternalLink={true}
              hasNoPaddingStart={true}
              href="https://www.statista.com/statistics/685134/south-africa-digital-population/"
            />
          </div>
        </Card>
      </section>
      <section className="container mt-5">
        <div className="mw-800">
          <h6>You Must To Go Where Your Customers Go</h6>
          <p>
            With a mobile-first, data-driven approach, iPhupha Digital helps
            sales and marketing functions digitally transform and enable key
            benefits to customers, their experiences, and, ultimately, increase
            revenue.
          </p>
        </div>
      </section>
      <section className="mt-5">
        <Card canHover={false} isPrimary={true} isPadded={false}>
          <div className="container pt-2 pb-2">
            <h6 className="mw-800">
              Up to 85% of digital investments do not meet their desired
              outcomes, because organisations have not been reimagined; or
              because there has not been a refocus of budget, people and
              resources.
            </h6>
            <TextButton
              label="Source: Deloitte | Digital Disruption Index – South Africa 2020"
              icon={faArrowRight}
              isExternalLink={true}
              hasNoPaddingStart={true}
              href="https://www2.deloitte.com/za/en/pages/digital-disruption-index.html"
            />
          </div>
        </Card>
      </section>
      <section className="container mt-5">
        <div className="mw-800">
          <h6>You Need A Coherent Strategy for Successful Optimizations</h6>
          <p>
            Don&apos;t make the mistake of not having a holistic plan
            encompassing the 6 critical areas required to achieve the right
            optimazations.
          </p>
        </div>
      </section>
      <section className="mt-5">
        <Card canHover={false} isPrimary={true} isPadded={false}>
          <div className="container pt-2 pb-4">
            <h6 className="mw-800">
              How Do We Achieve the Right Optimizations?
            </h6>
            <p className="mw-800">
              We analyse 6 critical areas to ensure that your sales and
              marketing functions digitally transform successfully and go on to
              become market leaders.
            </p>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <Card isPrimary={false} canHover={false}>
                  <h6 className="subtitle2">Architecture</h6>
                  <p className="m-0">
                    How infrastructure supports digital business
                  </p>
                </Card>
              </div>
              <div className="col-xs-12 col-md-6">
                <Card isPrimary={false} canHover={false}>
                  <h6 className="subtitle2">Strategy &amp; Culture</h6>
                  <p className="m-0">
                    Championing digital strategy and aligning the business
                  </p>
                </Card>
              </div>
              <div className="col-xs-12 col-md-6">
                <Card isPrimary={false} canHover={false}>
                  <h6 className="subtitle2">Innovation Culture</h6>
                  <p className="m-0">Business support for innovation</p>
                </Card>
              </div>
              <div className="col-xs-12 col-md-6">
                <Card isPrimary={false} canHover={false}>
                  <h6 className="subtitle2">Data &amp; Analytics</h6>
                  <p className="m-0">
                    Maturity of data and analytics capabilities
                  </p>
                </Card>
              </div>
              <div className="col-xs-12 col-md-6">
                <Card isPrimary={false} canHover={false}>
                  <h6 className="subtitle2">Customer Insights</h6>
                  <p className="m-0">Quality of knowledge about the customer</p>
                </Card>
              </div>
              <div className="col-xs-12 col-md-6">
                <Card isPrimary={false} canHover={false}>
                  <h6 className="subtitle2">Digital Experience</h6>
                  <p className="m-0">
                    Meeting the needs of the business and customer
                  </p>
                </Card>
              </div>
            </div>
          </div>
        </Card>
      </section>
      <section className="container mt-5">
        <div className="mw-800">
          <h6>Results in four to six weeks</h6>
          <p>
            In four to six weeks we will provide an analysis of obstacles
            causing revenue leakage, an actionable roadmap for immediate
            optimization and a baseline for ongoing assessment.
          </p>
        </div>
      </section>
      <section className="container mt-5">
        <p>Required fields are marked with an asterisk (*).</p>
        <Card canHover={false}>
          <h6 className="mb-4 mw-800">
            Let&apos;s talk about how optimization can drive increased revenue
            for your business.
          </h6>
          <ContactFormContextProvider>
            <ContactForm
              defaultSubject="business_solution"
              subjects={[
                { value: "business_solution", label: "Business Solution" },
              ]}
            />
          </ContactFormContextProvider>
        </Card>
      </section>
    </Layout>
  )
}

export default OptimizingYourSalesAndMarketingPage
